import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query/react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { logsApi } from 'redux-store/api/log-api'
import { projectsApi } from 'redux-store/api/project-api'
import { reportsApi } from 'redux-store/api/report-api'
import { userApi } from 'redux-store/api/user-api'

export type RootState = ReturnType<typeof rootReducer>
type AppStore = ReturnType<typeof configureStore>
export type AppDispatch = AppStore['dispatch']

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const rootReducer = combineReducers({
    [projectsApi.reducerPath]: projectsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [logsApi.reducerPath]: logsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(projectsApi.middleware)
            .concat(reportsApi.middleware)
            .concat(logsApi.middleware)
            .concat(userApi.middleware),
})

setupListeners(store.dispatch)
