import { ColumnDef } from '@tanstack/react-table'
import { DataTableSmallColumnHeader } from 'components/data-table-small-column-header'
import { TableDateCell } from 'components/table-date-cell'
import { TableUrlCell } from 'components/table-url-cell'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { DetailsLocationCell } from 'modules/details/cells/details-location-cell'
import { DetailsMapCell } from 'modules/details/cells/details-map-cell'
import { DetailsScreenCell } from 'modules/details/cells/details-screen-cell'
import { DetailsUserAgentCell } from 'modules/details/cells/details-user-agent-cell'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'

const defaultButtonProps = {
    buttonClassName: 'text-xs h-auto min-h-[36px]',
}

export const detailsColumns: ColumnDef<ReportSchemaType>[] = [
    {
        accessorKey: 'date',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Date" {...defaultButtonProps} />,
        cell: ({ row }) => <TableDateCell date={row.getValue('date')} />,
        sortingFn: 'datetime',
    },

    //

    {
        accessorKey: 'locationHref',
        header: ({ column }) => <DataTableColumnHeader column={column} title="URL" {...defaultButtonProps} />,
        cell: ({ row }) => <TableUrlCell url={row.getValue('locationHref')} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'ip',
        header: ({ column }) => <DataTableColumnHeader column={column} title="IP" {...defaultButtonProps} />,
        sortingFn: 'alphanumeric',
    },
    {
        accessorKey: 'screen',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Screen" {...defaultButtonProps} />,
        cell: ({ row }) => <DetailsScreenCell screen={row.getValue('screen')} />,
        sortingFn: 'alphanumeric',
    },
    {
        accessorKey: 'userAgent',
        header: () => <DataTableSmallColumnHeader>User Agent</DataTableSmallColumnHeader>,
        cell: ({ row }) => <DetailsUserAgentCell userAgent={row.getValue('userAgent')} />,
    },

    //

    {
        accessorKey: 'location',
        header: () => <DataTableSmallColumnHeader>Location</DataTableSmallColumnHeader>,
        cell: ({ row }) => (
            <DetailsLocationCell
                countryCode={row.getValue('countryCode')}
                country={row.getValue('country')}
                city={row.getValue('city')}
            />
        ),
    },
    {
        accessorKey: 'continent',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Continent" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'continentCode',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Continent Code" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'country',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Country" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'countryCode',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Country Code" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'city',
        header: ({ column }) => <DataTableColumnHeader column={column} title="City" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'region',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Region" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'regionName',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Region Name" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'lat',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Latitude" {...defaultButtonProps} />,
        sortingFn: 'alphanumeric',
    },
    {
        accessorKey: 'lng',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Longitude" {...defaultButtonProps} />,
        sortingFn: 'alphanumeric',
    },

    //

    {
        accessorKey: 'internetProvider',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Internet Provider" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'organization',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Organization" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'as',
        header: ({ column }) => <DataTableColumnHeader column={column} title="AS" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'asName',
        header: ({ column }) => <DataTableColumnHeader column={column} title="AS Name" {...defaultButtonProps} />,
        sortingFn: 'text',
    },

    //

    {
        accessorKey: 'postalCode',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Postal Code" {...defaultButtonProps} />,
        sortingFn: 'alphanumeric',
    },
    {
        accessorKey: 'timeZone',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Timezone" {...defaultButtonProps} />,
        sortingFn: 'text',
    },

    //

    {
        accessorKey: 'mobile',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Mobile" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'proxy',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Proxy" {...defaultButtonProps} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'hosting',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Hosting" {...defaultButtonProps} />,
        sortingFn: 'text',
    },

    //

    {
        accessorKey: 'map',
        header: () => <DataTableSmallColumnHeader>Map</DataTableSmallColumnHeader>,
        cell: ({ row }) => <DetailsMapCell lat={row.getValue('lat')} lng={row.getValue('lng')} />,
    },
]
