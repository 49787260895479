import { Button } from 'components/ui/button'
import { urlConfig } from 'config/url.config'
import { ProjectSchemaType, ProjectStatus } from 'modules/project/schemas/project.schema'
import { forwardRef, memo } from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'utils/cn'

interface DashboardDetailsCellProps extends React.ComponentProps<typeof Button> {
    id: ProjectSchemaType['id']
    status: ProjectStatus
}
export const DashboardDetailsCell = memo(
    forwardRef<React.ComponentRef<typeof Button>, DashboardDetailsCellProps>(({ id, status, ...props }, ref) => {
        return (
            <Button ref={ref} variant="outline" size="sm" asChild {...props} className="w-full max-w-[150px]">
                <Link
                    to={`${urlConfig.pages.details}/${id}`}
                    className={cn(status === ProjectStatus.PENDING && 'pointer-events-none opacity-50')}
                >
                    Details
                </Link>
            </Button>
        )
    }),
)
DashboardDetailsCell.displayName = 'DashboardDetailsCell'
