import * as SheetPrimitive from '@radix-ui/react-dialog'
import { cva, type VariantProps } from 'class-variance-authority'
import { X } from 'lucide-react'
import { forwardRef, memo } from 'react'
import { cn } from 'utils/cn'

const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const SheetPortal: React.FC<SheetPrimitive.DialogPortalProps> = memo(({ className, ...props }) => (
    <SheetPrimitive.Portal className={cn(className)} {...props} />
))
SheetPortal.displayName = SheetPrimitive.Portal.displayName

const SheetOverlay = memo(
    forwardRef<React.ElementRef<typeof SheetPrimitive.Overlay>, React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>>(
        ({ className, ...props }, ref) => (
            <SheetPrimitive.Overlay
                className={cn(
                    'bg-background/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 backdrop-blur-sm',
                    className,
                )}
                {...props}
                ref={ref}
            />
        ),
    ),
)
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

const sheetVariants = cva(
    'fixed z-50 gap-4 bg-background p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
    {
        variants: {
            side: {
                top: 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
                bottom: 'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
                left: 'inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm',
                right: 'inset-y-0 right-0 h-full w-3/4  border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm',
            },
        },
        defaultVariants: {
            side: 'right',
        },
    },
)

interface SheetContentProps
    extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
        VariantProps<typeof sheetVariants> {}

const SheetContent = memo(
    forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(
        ({ side = 'right', className, children, ...props }, ref) => (
            <SheetPortal>
                <SheetOverlay />
                <SheetPrimitive.Content ref={ref} className={cn(sheetVariants({ side }), className)} {...props}>
                    {children}
                    <SheetPrimitive.Close className="ring-offset-background focus:ring-ring data-[state=open]:bg-secondary absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none">
                        <X className="h-4 w-4" />
                        <span className="sr-only">Close</span>
                    </SheetPrimitive.Close>
                </SheetPrimitive.Content>
            </SheetPortal>
        ),
    ),
)
SheetContent.displayName = SheetPrimitive.Content.displayName

const SheetHeader = memo(({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('flex flex-col space-y-2 text-center sm:text-left', className)} {...props} />
))
SheetHeader.displayName = 'SheetHeader'

const SheetFooter = memo(({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} {...props} />
))
SheetFooter.displayName = 'SheetFooter'

const SheetTitle = memo(
    forwardRef<React.ElementRef<typeof SheetPrimitive.Title>, React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>>(
        ({ className, ...props }, ref) => (
            <SheetPrimitive.Title ref={ref} className={cn('text-foreground text-lg font-semibold', className)} {...props} />
        ),
    ),
)
SheetTitle.displayName = SheetPrimitive.Title.displayName

const SheetDescription = memo(
    forwardRef<
        React.ElementRef<typeof SheetPrimitive.Description>,
        React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
    >(({ className, ...props }, ref) => (
        <SheetPrimitive.Description ref={ref} className={cn('text-muted-foreground text-sm', className)} {...props} />
    )),
)
SheetDescription.displayName = SheetPrimitive.Description.displayName

export { Sheet, SheetClose, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger }
