import { BarChart } from 'components/bar-chart'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { forwardRef, memo, useMemo } from 'react'
import { UAParser } from 'ua-parser-js'

interface DetailsOsChartProps extends React.ComponentProps<typeof Card> {
    data: ReportSchemaType[]
}
export const DetailsOsChart = memo(
    forwardRef<React.ComponentRef<typeof Card>, DetailsOsChartProps>(({ data, ...props }, ref) => {
        const chartData = useMemo(() => {
            const modifiedData = data.map(item => {
                const ua = new UAParser(item.userAgent)
                return {
                    ...item,
                    os: ua.getOS().name,
                }
            })
            const os = [...new Set(modifiedData.map(item => item.os))]
            return os
                .map(item => ({
                    name: item ?? 'Other',
                    value: modifiedData.filter(i => i.os === item).length,
                }))
                .sort((a, b) => {
                    if (a.name === 'Other') return 1
                    if (b.name === 'Other') return -1
                    return b.value - a.value
                })
        }, [data])

        return (
            <Card ref={ref} {...props}>
                <CardHeader>
                    <CardTitle>Operating systems</CardTitle>
                    <CardDescription>Which are used by your users</CardDescription>
                </CardHeader>
                <CardContent>
                    <BarChart data={chartData} height={350} />
                </CardContent>
            </Card>
        )
    }),
)
DetailsOsChart.displayName = 'DetailsOsChart'
