import { BarChart } from 'components/bar-chart'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { forwardRef, memo, useMemo } from 'react'

interface DetailsCountriesChartProps extends React.ComponentProps<typeof Card> {
    data: ReportSchemaType[]
}
export const DetailsCountriesChart = memo(
    forwardRef<React.ComponentRef<typeof Card>, DetailsCountriesChartProps>(({ data, ...props }, ref) => {
        const chartData = useMemo(() => {
            const modifiedData = [...new Set(data.map(item => item.country))]
            return modifiedData
                .map(item => ({
                    name: item ?? 'Other',
                    value: data.filter(i => i.country === item).length,
                }))
                .sort((a, b) => {
                    if (a.name === 'Other') return 1
                    if (b.name === 'Other') return -1
                    return b.value - a.value
                })
        }, [data])

        return (
            <Card ref={ref} {...props}>
                <CardHeader>
                    <CardTitle>Countries</CardTitle>
                    <CardDescription>Where are your users from</CardDescription>
                </CardHeader>
                <CardContent>
                    <BarChart data={chartData} height={350} />
                </CardContent>
            </Card>
        )
    }),
)
DetailsCountriesChart.displayName = 'DetailsCountriesChart'
