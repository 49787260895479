import { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { ManageUsersRoleCell } from 'modules/manage-users/cells/manage-users-role-cell'
import { ManageUsersRowActionsCell } from 'modules/manage-users/cells/manage-users-row-actions-cell'
import { UserSchemaType } from 'modules/user/schemas/user.schema'

export const manageUsersColumns: ColumnDef<UserSchemaType>[] = [
    {
        accessorKey: 'id',
        header: 'ID',
    },
    {
        accessorKey: 'email',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'role',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Role" />,
        cell: ({ row }) => <ManageUsersRoleCell role={row.getValue('role')} />,
        sortingFn: 'text',
        filterFn: 'weakEquals',
    },
    {
        accessorKey: 'actions',
        header: 'Actions',
        cell: ({ row }) => <ManageUsersRowActionsCell id={row.getValue('id')} email={row.getValue('email')} />,
    },
]
