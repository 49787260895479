import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { forwardRef, memo } from 'react'

interface DetailsScreenCellProps extends React.ComponentProps<'span'> {
    screen: ReportSchemaType['screen']
}
export const DetailsScreenCell = memo(
    forwardRef<React.ComponentRef<'span'>, DetailsScreenCellProps>(({ screen, ...props }, ref) => {
        if (!screen) {
            return (
                <span ref={ref} {...props}>
                    Unknown
                </span>
            )
        }

        return (
            <span ref={ref} {...props}>
                {screen.width}x{screen.height}
            </span>
        )
    }),
)
DetailsScreenCell.displayName = 'DetailsScreenCell'
