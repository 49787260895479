import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from 'components/ui/toast'
import { TOAST_REMOVE_DELAY, useToast } from 'components/ui/use-toast'
import { cn } from 'utils/cn'

export const Toaster: React.FC = () => {
    const { toasts } = useToast()

    return (
        <ToastProvider duration={TOAST_REMOVE_DELAY}>
            {toasts.map(({ id, title, description, action, className, ...props }) => {
                return (
                    <Toast key={id} {...props} className={cn('mt-1', className)}>
                        <div className="grid gap-1">
                            {title && <ToastTitle>{title}</ToastTitle>}
                            {description && <ToastDescription>{description}</ToastDescription>}
                        </div>
                        {action}
                        <ToastClose />
                    </Toast>
                )
            })}
            <ToastViewport />
        </ToastProvider>
    )
}
