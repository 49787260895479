import { urlConfig } from 'config/url.config'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { forwardRef, memo } from 'react'
import { cn } from 'utils/cn'

interface DetailsLocationCellProps extends React.ComponentProps<'div'> {
    countryCode: ReportSchemaType['countryCode']
    country: ReportSchemaType['country']
    city: ReportSchemaType['city']
}
export const DetailsLocationCell = memo(
    forwardRef<React.ComponentRef<'div'>, DetailsLocationCellProps>(
        ({ countryCode, country, city, className, ...props }, ref) => {
            return (
                <div
                    ref={ref}
                    {...props}
                    className={cn('flex min-w-[150px] max-w-[200px] items-center gap-2 whitespace-break-spaces pr-8', className)}
                >
                    <img src={urlConfig.external.flagsApi(countryCode ?? '')} className="w-8 drop-shadow" />
                    <span>
                        {country}, {city}
                    </span>
                </div>
            )
        },
    ),
)
DetailsLocationCell.displayName = 'DetailsLocationCell'
