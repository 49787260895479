import { Button } from 'components/ui/button'
import { defaultIconProps } from 'config/constants.config'
import { urlConfig } from 'config/url.config'
import { Map } from 'lucide-react'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { forwardRef, memo } from 'react'

interface DetailsMapCellProps extends React.ComponentProps<typeof Button> {
    lat: ReportSchemaType['lat']
    lng: ReportSchemaType['lng']
}
export const DetailsMapCell = memo(
    forwardRef<React.ComponentRef<typeof Button>, DetailsMapCellProps>(({ lat, lng, ...props }, ref) => {
        return (
            <Button ref={ref} variant="outline" size="icon" asChild {...props}>
                <a href={urlConfig.external.googleMaps(lat ?? 0, lng ?? 0)} target="_blank">
                    <Map {...defaultIconProps} />
                </a>
            </Button>
        )
    }),
)
DetailsMapCell.displayName = 'DetailsMapCell'
