import { SlotItemWithIconReversed } from 'components/slot-item-with-icon'
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { urlConfig } from 'config/url.config'
import { LogIn, LogOut } from 'lucide-react'
import { getFallbackLetters } from 'modules/user/utils/get-fallback-letters'
import { forwardRef, memo, useCallback } from 'react'
import { useAuthUser, useIsAuthenticated, useSignOut } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'

interface UserAvatarProps extends React.ComponentProps<typeof DropdownMenuTrigger> {}
export const UserAvatar = memo(
    forwardRef<React.ComponentRef<typeof DropdownMenuTrigger>, UserAvatarProps>(({ ...props }, ref) => {
        const auth = useAuthUser()()
        const isAuth = useIsAuthenticated()()
        const signOut = useSignOut()
        const navigate = useNavigate()

        const onSignIn = useCallback(() => {
            navigate(urlConfig.pages.login)
        }, [navigate])

        const onSignOut = useCallback(() => {
            signOut()
        }, [signOut])

        return (
            <DropdownMenu>
                <DropdownMenuTrigger ref={ref} {...props}>
                    <Avatar className="h-9 w-9">
                        <AvatarImage src={auth?.image ?? ''} />
                        <AvatarFallback className="capitalize">
                            {getFallbackLetters(auth?.name ?? auth?.email ?? 'U')}
                        </AvatarFallback>
                    </Avatar>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    {isAuth ? (
                        <DropdownMenuItem onClick={onSignOut}>
                            <SlotItemWithIconReversed Icon={LogOut}>Log out</SlotItemWithIconReversed>
                        </DropdownMenuItem>
                    ) : (
                        <DropdownMenuItem onClick={onSignIn}>
                            <SlotItemWithIconReversed Icon={LogIn}>Log in</SlotItemWithIconReversed>
                        </DropdownMenuItem>
                    )}
                </DropdownMenuContent>
            </DropdownMenu>
        )
    }),
)
UserAvatar.displayName = 'UserAvatar'
