import { UserSchemaType } from 'modules/user/schemas/user.schema'
import { forwardRef, memo } from 'react'
import { cn } from 'utils/cn'

interface ManageUsersRoleCellProps extends React.ComponentProps<'span'> {
    role: UserSchemaType['role']
}
export const ManageUsersRoleCell = memo(
    forwardRef<React.ComponentRef<'span'>, ManageUsersRoleCellProps>(({ role, className, ...props }, ref) => {
        return (
            <span ref={ref} {...props} className={cn('capitalize', className)}>
                {role}
            </span>
        )
    }),
)
ManageUsersRoleCell.displayName = 'ManageUsersRoleCell'
