import { z } from 'zod'

export enum ProjectStatus {
    ACTIVE = 'active',
    DISABLED = 'disabled',
    PENDING = 'pending',
}

export const ProjectSchema = z.object({
    id: z.string().nonempty(),
    name: z.string().nonempty(),
    url: z.string().nonempty().url(),
    status: z.nativeEnum(ProjectStatus),
})

export type ProjectSchemaType = z.infer<typeof ProjectSchema>

export const CreateProjectSchema = z.object({
    name: z.string().min(3, 'Minimum 2 characters').max(300, 'Maximum 300 characters'),
    url: z.string().url('Invalid URL'),
})

export type CreateProjectSchemaType = z.infer<typeof CreateProjectSchema>
