import { zodResolver } from '@hookform/resolvers/zod'
import { FullPageLoader } from 'components/full-page-loader'
import { Heading } from 'components/heading'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table'
import { DataTableFacetedFilter } from 'components/ui/data-table-faceted-filter'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { toastConfig } from 'config/toast.config'
import { manageUsersColumns } from 'modules/manage-users/components/manage-users-columns'
import { ManageUsersCreateForm } from 'modules/manage-users/components/manage-users-create-form'
import { NewUserSchema, NewUserSchemaType, UserRole } from 'modules/user/schemas/user.schema'
import { memo, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useCreateUserMutation, useGetAllUsersQuery } from 'redux-store/api/user-api'

interface ManageUsersPageProps {}
export const ManageUsersPage: React.FC<ManageUsersPageProps> = memo(() => {
    const [disabled, setDisabled] = useState<boolean>(false)

    const { data, isLoading } = useGetAllUsersQuery()
    const [createUser, createUserRes] = useCreateUserMutation()

    const form = useForm<NewUserSchemaType>({
        resolver: zodResolver(NewUserSchema),
        defaultValues: {
            email: '',
            role: UserRole.USER,
        },
    })

    const onSubmit = useCallback<(values: NewUserSchemaType) => Promise<void>>(
        async values => {
            try {
                setDisabled(true)

                await createUser({
                    email: values.email,
                    role: values.role,
                }).unwrap()
                if (createUserRes.isError) {
                    console.error(createUserRes.error)
                    throw new Error('Failed to create user')
                }

                toastConfig.user.create.success(values.email)
                form.reset()
            } catch (error) {
                console.error(error)
                toastConfig.user.create.error()
            } finally {
                setDisabled(false)
            }
        },
        [createUser, createUserRes.error, createUserRes.isError, form],
    )

    if (isLoading) {
        return <FullPageLoader />
    }

    return (
        <div className="animate-in fade-in-0 container min-h-screen space-y-5 pt-20 transition-all duration-300">
            <Heading title="Manage Users" description="Create, delete, everything is in your hands!" />
            <div className="flex gap-5">
                {/* create user */}
                <Card className="min-w-[280px]">
                    <CardHeader>
                        <CardTitle>Create new User</CardTitle>
                        <CardDescription>Add email, select role and that's all!</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <ManageUsersCreateForm form={form} onSubmit={onSubmit} isButtonDisabled={disabled} />
                    </CardContent>
                </Card>
                {/* users table */}
                <DataTable
                    columns={manageUsersColumns}
                    data={data ?? []}
                    pagination
                    initialSorting={{
                        column: 'email',
                        direction: 'asc',
                    }}
                    initialHidden={['id']}
                    className="w-full"
                >
                    <DataTableToolbar>
                        <DataTableInputFilter column="email" label="Email" />
                        <DataTableFacetedFilter column="role" options={Object.values(UserRole).map(role => ({ data: role }))} />
                    </DataTableToolbar>
                </DataTable>
            </div>
        </div>
    )
})
ManageUsersPage.displayName = 'ManageUsersPage'
