import { ENV, dotenv } from 'config/dotenv.config'

export const urlConfig = {
    pages: {
        main: '/',
        dashboard: '/',
        details: '/details',
        detailsId: '/details/:id',
        login: '/login',
        logs: '/logs',
        manageUsers: '/manage-users',
    },
    api: {
        login: '/login',
        counter: '/counter',
        project: '/project',
        revalidate: '/revalidate',
        projectLogs: '/project-logs',
        appLogs: '/app-logs',
    },
    API_URL: dotenv.get(ENV.API_URL),
    external: {
        flagsApi: (countryCode: string) => `https://flagsapi.com/${countryCode}/flat/32.png`,
        googleMaps: (lat: number, lng: number) => `https://www.google.com.ua/maps/place/${lat},${lng}`,
    },
}
