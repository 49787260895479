import { Button } from 'components/ui/button'
import { InlineCode } from 'components/ui/code'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { toast } from 'components/ui/use-toast'
import { defaultIconProps } from 'config/constants.config'
import { MoreHorizontal } from 'lucide-react'
import { UserSchemaType } from 'modules/user/schemas/user.schema'
import { forwardRef, memo, useCallback } from 'react'
import { useDeleteUserMutation } from 'redux-store/api/user-api'

interface ManageUsersRowActionsCellProps extends Omit<React.ComponentProps<typeof DropdownMenuTrigger>, 'children'> {
    id: UserSchemaType['id']
    email: UserSchemaType['email']
    children?: UserSchemaType
}
export const ManageUsersRowActionsCell = memo(
    forwardRef<React.ComponentRef<typeof DropdownMenuTrigger>, ManageUsersRowActionsCellProps>(({ id, email, ...props }, ref) => {
        const [deleteUser, deleteUserRes] = useDeleteUserMutation()

        const onDelete = useCallback<() => void>(() => {
            deleteUser(id)
            if (deleteUserRes.isError) {
                console.error(deleteUserRes.error)
                toast({
                    title: 'Failed to delete user',
                    description: (
                        <>
                            Error occured while deleting user with email <InlineCode>{email}</InlineCode>. Please try again later
                        </>
                    ),
                    variant: 'destructive',
                })
                return
            }
            toast({
                title: 'User deleted',
                description: (
                    <>
                        User with email <InlineCode>{email}</InlineCode> deleted successfully
                    </>
                ),
            })
        }, [deleteUser, deleteUserRes.error, deleteUserRes.isError, email, id])

        return (
            <DropdownMenu>
                <DropdownMenuTrigger ref={ref} asChild {...props}>
                    <Button variant="ghost" size="icon" className="data-[state=open]:bg-muted flex h-8 w-8 p-0">
                        <MoreHorizontal {...defaultIconProps} className="h-4 w-4" />
                        <span className="sr-only">Open more</span>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-[160px]">
                    <DropdownMenuItem onClick={onDelete}>Delete</DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        )
    }),
)
ManageUsersRowActionsCell.displayName = 'ManageUsersRowActionsCell'
