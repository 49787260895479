import { IpSchema } from 'modules/user/schemas/ip.schema'

type GetUserIp = () => Promise<string>
export const getUserIp: GetUserIp = async () => {
    try {
        const resPromise = await fetch('https://api.ipify.org?format=json')
        const resJson = await resPromise.json()
        const resParse = IpSchema.safeParse(resJson)
        if (!resParse.success) {
            throw new Error('Invalid IP')
        }
        return resParse.data.ip
    } catch (error) {
        return '0.0.0.0'
    }
}
