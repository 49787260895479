import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { LogType } from 'modules/logs/types/log.type'

export const logsApi = createApi({
    reducerPath: 'logApi',
    tagTypes: ['Log'],
    keepUnusedDataFor: 0,
    // refetchOnFocus: true,
    // refetchOnReconnect: true,
    // refetchOnMountOrArgChange: true,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAuthLogs: builder.query<LogType, void>({
            query: () => ({
                url: '/log/auth',
                method: 'GET',
                responseHandler: response => response.text(),
            }),
            providesTags: ['Log'],
        }),
        getInfoLogs: builder.query<LogType, void>({
            query: () => ({
                url: '/log/info',
                method: 'GET',
                responseHandler: response => response.text(),
            }),
            providesTags: ['Log'],
        }),
        getDebugLogs: builder.query<LogType, void>({
            query: () => ({
                url: '/log/debug',
                method: 'GET',
                responseHandler: response => response.text(),
            }),
            providesTags: ['Log'],
        }),
        getWarnLogs: builder.query<LogType, void>({
            query: () => ({
                url: '/log/warn',
                method: 'GET',
                responseHandler: response => response.text(),
            }),
            providesTags: ['Log'],
        }),
        getErrorLogs: builder.query<LogType, void>({
            query: () => ({
                url: '/log/error',
                method: 'GET',
                responseHandler: response => response.text(),
            }),
            providesTags: ['Log'],
        }),
    }),
})

export const { useGetAuthLogsQuery, useGetInfoLogsQuery, useGetDebugLogsQuery, useGetWarnLogsQuery, useGetErrorLogsQuery } =
    logsApi
