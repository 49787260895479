import { OnSignInWithGoogle } from 'components/sign-in-with-google'
import { firebaseAuth } from 'config/firebase.config'
import { toastConfig } from 'config/toast.config'
import { urlConfig } from 'config/url.config'
import { isSignInWithEmailLink, sendSignInLinkToEmail } from 'firebase/auth'
import { AUTH_LOCAL_STORAGE_KEY } from 'modules/user/hoc/auth-provider'
import { UserRole } from 'modules/user/schemas/user.schema'
import { getUserIp } from 'modules/user/utils/get-user-ip'
import ms from 'ms'
import { useCallback, useEffect, useState } from 'react'
import { useSignIn } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'

type AuthState = Parameters<OnSignInWithGoogle>[0]

export const useLogin = () => {
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

    const signIn = useSignIn()
    const navigate = useNavigate()

    const login = useCallback<(params: { authState: AuthState; callbackUrl: string }) => Promise<void>>(
        async ({ authState, callbackUrl }) => {
            try {
                if (!authState.name || !authState.email) {
                    throw new Error('Invalid auth state. Name and email are required')
                }

                setIsButtonDisabled(true)

                const resPromise = await fetch(`${urlConfig.API_URL}${urlConfig.api.login}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: authState.email,
                        ip: await getUserIp(),
                    }),
                })
                if (!resPromise.ok) {
                    throw new Error('Response error')
                }

                const res = (await resPromise.json()) as {
                    message: string
                    token: string
                    role: UserRole
                }
                if (!res.token) {
                    throw new Error('Invalid response data')
                }

                signIn({
                    token: res.token,
                    expiresIn: ms('14d'),
                    tokenType: 'Bearer',
                    authState: {
                        ...authState,
                        role: res.role,
                    },
                })
                toastConfig.login.success(authState.name)
                navigate(callbackUrl)
            } catch (error) {
                console.error(error)
                toastConfig.login.error()
            } finally {
                setIsButtonDisabled(false)
            }
        },
        [navigate, signIn],
    )

    const signInWithGoogle = useCallback<OnSignInWithGoogle>(
        async user => {
            await login({
                authState: {
                    name: 'Unknown user',
                    ...user,
                },
                callbackUrl: urlConfig.pages.main,
            })
        },
        [login],
    )

    const signInWithEmailLink = useCallback<(email: string, cb?: () => void | Promise<void>) => Promise<void>>(
        async (email, cb) => {
            try {
                await sendSignInLinkToEmail(firebaseAuth, email, {
                    url: window.location.href,
                    handleCodeInApp: true,
                })
                localStorage.setItem(AUTH_LOCAL_STORAGE_KEY.GOOGLE_PASSWORDLESS, email)
                cb?.()
                toastConfig.login.successWithEmail(email)
            } catch (error) {
                toastConfig.login.error()
            }
        },
        [],
    )

    // sign in with google email passwordless link
    useEffect(() => {
        try {
            if (!isSignInWithEmailLink(firebaseAuth, window.location.href)) {
                return
            }
            const email = window.localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.GOOGLE_PASSWORDLESS)
            window.localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.GOOGLE_PASSWORDLESS)
            if (!email) {
                return
            }
            login({
                authState: {
                    email,
                    name: email.split('@')[0],
                },
                callbackUrl: urlConfig.pages.main,
            })
        } catch (error) {
            toastConfig.login.error()
        }
    }, [login])

    return {
        isButtonDisabled,
        login,
        signInWithGoogle,
        signInWithEmailLink,
    } as const
}
