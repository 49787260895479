import { ProjectSchemaType } from 'modules/project/schemas/project.schema'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

interface UseProjectStore {
    isCreateProjectOpen: boolean
    isSnippetsModalOpen: boolean
    id: ProjectSchemaType['id']
    name: ProjectSchemaType['name']

    //

    setCreateProjectOpen: (isOpen: boolean) => void
    openSnippetsModal: ({ id, name }: { id: UseProjectStore['id']; name: UseProjectStore['name'] }) => void
    closeSnippetsModal: () => void
}

export const useProjectStore = create<
    UseProjectStore,
    [['zustand/devtools', UseProjectStore], ['zustand/persist', UseProjectStore], ['zustand/immer', UseProjectStore]]
>(
    devtools(
        persist(
            immer(set => ({
                isCreateProjectOpen: false as boolean,
                isSnippetsModalOpen: false as boolean,
                id: '',
                name: '',

                //

                setCreateProjectOpen: isOpen => {
                    set(state => {
                        state.isCreateProjectOpen = isOpen
                    })
                },

                openSnippetsModal: ({ id, name }) => {
                    set(state => {
                        state.isSnippetsModalOpen = true
                        state.id = id
                        state.name = name
                    })
                },

                closeSnippetsModal: () => {
                    set(state => {
                        state.isSnippetsModalOpen = false
                        state.id = ''
                        state.name = ''
                    })
                },
            })),
            {
                name: 'project',
            },
        ),
    ),
)
