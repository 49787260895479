import { forwardRef, memo } from 'react'
import { cn } from 'utils/cn'

interface DataTableSmallColumnHeaderProps extends React.ComponentProps<'span'> {}
export const DataTableSmallColumnHeader = memo(
    forwardRef<React.ComponentRef<'span'>, DataTableSmallColumnHeaderProps>(({ className, ...props }, ref) => {
        return <span ref={ref} {...props} className={cn('text-xs', className)} />
    }),
)
DataTableSmallColumnHeader.displayName = 'DataTableSmallHeader'
