import { CodeSnippet } from 'components/code-snippet'
import { ProjectSnippetProps } from 'modules/project/types/project-snippet-props.type'
import { memo } from 'react'

export const ReactSnippet: React.FC<ProjectSnippetProps> = memo(({ url }) => {
    return (
        <CodeSnippet language="tsx" label="React JSX">
            {`import { useEffect } from 'react'

interface ComponentProps {}

export const Component: React.FC<ComponentProps> = () => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = '${url}'
        script.defer = true
        document.body.appendChild(script)
        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return <></>
}

Component.displayName = 'Component'`}
        </CodeSnippet>
    )
})
ReactSnippet.displayName = 'ReactSnippet'
