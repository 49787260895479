import { Icons } from 'components/icons'
import { Button } from 'components/ui/button'
import { firebaseAuth, firebaseProvider } from 'config/firebase.config'
import { AdditionalUserInfo, User, getAdditionalUserInfo, signInWithPopup } from 'firebase/auth'
import { forwardRef, memo, useCallback } from 'react'

export type OnSignInWithGoogle = (
    user: { id?: string; name?: string; email?: string; image?: string },
    other?: {
        userInfo: User
        additionalUserInfo?: AdditionalUserInfo['profile']
    },
) => void

interface SignInWithGoogleProps extends Omit<React.ComponentProps<typeof Button>, 'onClick'> {
    onClick?: OnSignInWithGoogle
    contentClassName?: string
}
export const SignInWithGoogle = memo(
    forwardRef<React.ComponentRef<typeof Button>, SignInWithGoogleProps>(
        ({ onClick, className, contentClassName, children, ...props }, ref) => {
            const onSignIn = useCallback<() => Promise<void>>(async () => {
                try {
                    const result = await signInWithPopup(firebaseAuth, firebaseProvider)
                    const additionalResult = getAdditionalUserInfo(result)

                    const userInfo = result.user
                    const additionalUserInfo = additionalResult?.profile

                    onClick?.(
                        {
                            id: userInfo?.uid ?? undefined,
                            name: userInfo?.displayName ?? undefined,
                            email: userInfo?.email ?? undefined,
                            image: userInfo?.photoURL ?? undefined,
                        },
                        {
                            userInfo,
                            additionalUserInfo,
                        },
                    )
                } catch (error) {
                    console.error(error)
                }
            }, [onClick])

            return (
                <Button ref={ref} variant="outline" onClick={onSignIn} {...props}>
                    <Icons.google className="mr-2 h-4 w-4" />
                    Google
                </Button>
            )
        },
    ),
)
SignInWithGoogle.displayName = 'SignInWithGoogle'
