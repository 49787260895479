import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { ProjectSchemaType } from 'modules/project/schemas/project.schema'

export const reportsApi = createApi({
    reducerPath: 'reportApi',
    tagTypes: ['Report'],
    keepUnusedDataFor: 0,
    // refetchOnFocus: true,
    // refetchOnReconnect: true,
    // refetchOnMountOrArgChange: true,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAllReports: builder.query<ReportSchemaType[], ProjectSchemaType['id']>({
            query: id => ({
                url: `/report/${id}`,
                method: 'GET',
            }),
            providesTags: ['Report'],
        }),
    }),
})

export const { useGetAllReportsQuery } = reportsApi
