import { ChartDataType } from 'components/bar-chart'
import { forwardRef, memo, useCallback, useState } from 'react'
import { Pie, PieChart as RechartsPieChart, ResponsiveContainer, Sector } from 'recharts'
import { cn } from 'utils/cn'

const noOutlineClassName = 'focus:outline-none focus:ring-0'

interface PieChartProps extends Omit<React.ComponentProps<typeof ResponsiveContainer>, 'children'> {
    data: ChartDataType
}
export const PieChart = memo(
    forwardRef<React.ComponentRef<typeof ResponsiveContainer>, PieChartProps>(({ data, ...props }, ref) => {
        const [activeIndex, setActiveIndex] = useState<number>(0)

        const onPieEnter = useCallback<(_: unknown, index: number) => void>((_, index) => {
            setActiveIndex(index)
        }, [])

        return (
            <ResponsiveContainer ref={ref} {...props}>
                <RechartsPieChart className={noOutlineClassName}>
                    {/* <Pie data={data} dataKey="value" radius={4} label className={cn('fill-primary', noOutlineClassName)} /> */}
                    <Pie
                        data={data}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        activeIndex={activeIndex}
                        activeShape={RenderActiveShape}
                        onMouseEnter={onPieEnter}
                        className={cn('fill-primary/20 stroke-none', noOutlineClassName)}
                    />
                </RechartsPieChart>
            </ResponsiveContainer>
        )
    }),
)
PieChart.displayName = 'PieChart'

interface RenderActiveShapeProps {
    cx: number
    cy: number
    midAngle: number
    innerRadius: number
    outerRadius: number
    startAngle: number
    endAngle: number
    fill: string
    payload: ChartDataType[0]
    percent: number
    value: number
}
const RenderActiveShape = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    payload,
    percent,
    value,
}: RenderActiveShapeProps) => {
    const RADIAN = Math.PI / 180
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const textAnchor = cos >= 0 ? 'start' : 'end'

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" className={cn('fill-foreground text-xs', noOutlineClassName)}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                className={cn('fill-primary', noOutlineClassName)}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                className={cn('fill-primary', noOutlineClassName)}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${my}`} className="stroke-primary" fill="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={my}
                textAnchor={textAnchor}
                className={cn('fill-foreground text-xs', noOutlineClassName)}
            >
                {value}
            </text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={my}
                dy={18}
                textAnchor={textAnchor}
                className={cn('fill-foreground text-xs', noOutlineClassName)}
            >
                ({(percent * 100).toFixed(2)}%)
            </text>
        </g>
    )
}
