import { CodeSnippet } from 'components/code-snippet'
import { ProjectSnippetProps } from 'modules/project/types/project-snippet-props.type'
import { memo } from 'react'

export const VueSnippet: React.FC<ProjectSnippetProps> = memo(({ url }) => {
    return (
        <CodeSnippet language="typescript" label="Vue">
            {`<template>
    <div></div>
</template>

<script>
export default {
    mounted() {
        const script = document.createElement('script')
        script.src = '${url}'
        script.defer = true
        document.body.appendChild(script)
    },
    beforeDestroy() {
        const script = document.querySelector('script[src="${url}"]')
        if (script) {
            document.body.removeChild(script)
        }
    },
}
</script>
`}
        </CodeSnippet>
    )
})
VueSnippet.displayName = 'VueSnippet'
