import { Button } from 'components/ui/button'
import { ProjectSchemaType } from 'modules/project/schemas/project.schema'
import { useProjectStore } from 'modules/project/store/project.store'
import React, { forwardRef, memo, useCallback } from 'react'

interface DashboardSnippetsCellProps extends React.ComponentProps<typeof Button> {
    id: ProjectSchemaType['id']
    name: ProjectSchemaType['name']
}
export const DashboardSnippetsCell = memo(
    forwardRef<React.ComponentRef<typeof Button>, DashboardSnippetsCellProps>(({ id, name, ...props }, ref) => {
        const openSnippetsModal = useProjectStore(state => state.openSnippetsModal)

        const onModalOpen = useCallback(() => {
            openSnippetsModal({
                id,
                name,
            })
        }, [id, name, openSnippetsModal])

        return (
            <Button ref={ref} variant="outline" onClick={onModalOpen} {...props}>
                Code Snippets
            </Button>
        )
    }),
)
DashboardSnippetsCell.displayName = 'DashboardSnippetsCell'
