import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { UserSchemaType } from 'modules/user/schemas/user.schema'

export const userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: ['User'],
    // refetchOnFocus: true,
    // refetchOnReconnect: true,
    // refetchOnMountOrArgChange: true,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAllUsers: builder.query<UserSchemaType[], void>({
            query: () => ({
                url: '/user',
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        createUser: builder.mutation<void, Omit<UserSchemaType, 'id'>>({
            query: admin => ({
                url: '/user',
                method: 'POST',
                body: admin,
            }),
            invalidatesTags: ['User'],
        }),
        deleteUser: builder.mutation<void, string>({
            query: id => ({
                url: `/user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User'],
        }),
    }),
})

export const { useGetAllUsersQuery, useCreateUserMutation, useDeleteUserMutation } = userApi
