import { CodeSnippet } from 'components/code-snippet'
import { ProjectSnippetProps } from 'modules/project/types/project-snippet-props.type'
import { memo } from 'react'

export const AngularSnippet: React.FC<ProjectSnippetProps> = memo(({ url }) => {
    return (
        <CodeSnippet language="typescript" label="Angular">
            {`import { Component, OnDestroy, OnInit } from '@angular/core'

@Component({
    selector: 'app-component',
    template: '',
})
export class Component implements OnInit, OnDestroy {
    ngOnInit(): void {
        const script = document.createElement('script')
        script.src = '${url}'
        script.defer = true
        document.body.appendChild(script)
    }

    ngOnDestroy(): void {
        const script = document.querySelector('script[src="${url}"]')
        if (script) {
            document.body.removeChild(script)
        }
    }
}`}
        </CodeSnippet>
    )
})
AngularSnippet.displayName = 'AngularSnippet'
