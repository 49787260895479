import { forwardRef, memo } from 'react'

interface HeadingProps extends React.ComponentProps<'div'> {
    title: string
    description?: string
}
export const Heading = memo(
    forwardRef<React.ComponentRef<'div'>, HeadingProps>(({ title, description, ...props }, ref) => {
        return (
            <div ref={ref} {...props}>
                <h2 className="text-3xl font-bold tracking-tight">{title}</h2>
                {description && <p className="text-muted-foreground text-sm">{description}</p>}
            </div>
        )
    }),
)
Heading.displayName = 'Heading'
