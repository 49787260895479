import { pageTransitionClassName } from 'config/constants.config'
import { Loader2 } from 'lucide-react'
import { forwardRef, memo } from 'react'
import { cn } from 'utils/cn'

interface FullPageLoaderProps extends React.ComponentProps<'div'> {}
export const FullPageLoader = memo(
    forwardRef<React.ComponentRef<'div'>, FullPageLoaderProps>(({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                {...props}
                className={cn(
                    'bg-background fixed left-0 top-0 flex h-screen w-screen items-center justify-center',
                    pageTransitionClassName,
                    className,
                )}
            >
                <Loader2 className="repeat-infinite text-muted-foreground animate-spin" />
            </div>
        )
    }),
)
FullPageLoader.displayName = 'FullPageLoader'
