import { HoverCard, HoverCardContent, HoverCardTrigger } from 'components/ui/hover-card'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { getBrowserIconName } from 'modules/user/utils/get-browser-icon-name'
import { getOsIconName } from 'modules/user/utils/get-os-icon-name'
import { forwardRef, memo, useMemo } from 'react'
import { UAParser } from 'ua-parser-js'
import { cn } from 'utils/cn'

const defaultUserAgentProps = {
    className: 'h-7 drop-shadow',
}

interface DetailsUserAgentCellProps extends React.ComponentProps<typeof HoverCardTrigger> {
    userAgent: ReportSchemaType['userAgent']
}
export const DetailsUserAgentCell = memo(
    forwardRef<React.ComponentRef<typeof HoverCardTrigger>, DetailsUserAgentCellProps>(
        ({ userAgent, className, ...props }, ref) => {
            const { browser, browserIcon, os, osIcon, fullResult } = useMemo(() => {
                const parser = new UAParser()
                parser.setUA(userAgent ?? '')
                const browser = parser.getBrowser()
                const os = parser.getOS()
                const browserIcon = getBrowserIconName(browser.name)
                const osIcon = getOsIconName(os.name)
                const fullResult = parser.getUA()
                return {
                    browser,
                    os,
                    browserIcon,
                    osIcon,
                    fullResult,
                }
            }, [userAgent])

            return (
                <HoverCard>
                    <HoverCardTrigger ref={ref} {...props} className={cn('flex cursor-pointer gap-2', className)}>
                        {browserIcon && <img src={`/assets/browsers/${browserIcon}.svg`} {...defaultUserAgentProps} />}
                        {osIcon && <img src={`/assets/os/${osIcon}.svg`} {...defaultUserAgentProps} />}
                        {!browserIcon && !osIcon && <span>Unknown</span>}
                    </HoverCardTrigger>
                    <HoverCardContent className="flex min-w-[300px] flex-col whitespace-break-spaces">
                        <span>
                            <strong>Short:</strong>
                            <br />
                            {browser.name} {browser.version} on {os.name} {os.version}
                        </span>
                        <br />
                        <span>
                            <strong>Full:</strong>
                            <br />
                            {fullResult}
                        </span>
                    </HoverCardContent>
                </HoverCard>
            )
        },
    ),
)
DetailsUserAgentCell.displayName = 'DetailsUserAgentCell'
