import { z } from 'zod'

export const LoginSchema = z.object({
    email: z
        .string()
        .email()
        .min(5, 'Email must be at least 5 characters long')
        .max(255, 'Email cannot be longer than 255 characters'),
})
export type LoginSchemaType = z.infer<typeof LoginSchema>
