type SubstringSearch = (whereToFind: string, whatToFind: string, accuracy?: number) => boolean
export const substringSearch: SubstringSearch = (whereToFind, whatToFind, accuracy = 0.8) => {
    const patternLength = whatToFind.length
    const thresholdLength = patternLength * accuracy

    for (let i = 0; i <= whereToFind.length - patternLength; i++) {
        const substring = whereToFind.substring(i, i + patternLength)
        let matchCount = 0

        for (let j = 0; j < patternLength; j++) {
            if (substring[j] === whatToFind[j]) {
                matchCount++
            }
        }

        if (matchCount >= thresholdLength) {
            return true
        }
    }

    return false
}
