import { FullPageLoader } from 'components/full-page-loader'
import { Heading } from 'components/heading'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { LogsCard } from 'modules/logs/logs-card'
import { memo } from 'react'
import {
    useGetAuthLogsQuery,
    useGetDebugLogsQuery,
    useGetErrorLogsQuery,
    useGetInfoLogsQuery,
    useGetWarnLogsQuery,
} from 'redux-store/api/log-api'

interface LogsPageProps {}
export const LogsPage: React.FC<LogsPageProps> = memo(() => {
    const { data: authLogs, isLoading: authLogsLoading } = useGetAuthLogsQuery()
    const { data: infoLogs, isLoading: infoLogsLoading } = useGetInfoLogsQuery()
    const { data: debugLogs, isLoading: debugLogsLoading } = useGetDebugLogsQuery()
    const { data: warnLogs, isLoading: warnLogsLoading } = useGetWarnLogsQuery()
    const { data: errorLogs, isLoading: errorLogsLoading } = useGetErrorLogsQuery()

    if (authLogsLoading || infoLogsLoading || debugLogsLoading || warnLogsLoading || errorLogsLoading) {
        return <FullPageLoader />
    }

    return (
        <div className="animate-in fade-in-0 bg-background container flex min-h-screen flex-col space-y-5 pt-24 transition-all duration-300">
            <Heading title="Logs" description="See what's happening on the server" />
            <Tabs defaultValue="Auth" className="w-full">
                <TabsList className="grid w-full grid-cols-5">
                    <TabsTrigger value="Auth">Auth</TabsTrigger>
                    <TabsTrigger value="Info">Info</TabsTrigger>
                    <TabsTrigger value="Debug">Debug</TabsTrigger>
                    <TabsTrigger value="Warn">Warn</TabsTrigger>
                    <TabsTrigger value="Error">Error</TabsTrigger>
                </TabsList>
                <TabsContent value="Auth">
                    <LogsCard title="Auth logs">{authLogs}</LogsCard>
                </TabsContent>
                <TabsContent value="Info">
                    <LogsCard title="Info logs">{infoLogs}</LogsCard>
                </TabsContent>
                <TabsContent value="Debug">
                    <LogsCard title="Debug logs">{debugLogs}</LogsCard>
                </TabsContent>
                <TabsContent value="Warn">
                    <LogsCard title="Warn logs">{warnLogs}</LogsCard>
                </TabsContent>
                <TabsContent value="Error">
                    <LogsCard title="Error logs">{errorLogs}</LogsCard>
                </TabsContent>
            </Tabs>
        </div>
    )
})
LogsPage.displayName = 'LogsCard'
