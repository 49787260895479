import { getLocaleDate } from 'config/dayjs.config'
import { forwardRef, memo } from 'react'

interface TableDateCellProps extends React.ComponentProps<'span'> {
    date: string
}
export const TableDateCell = memo(
    forwardRef<React.ComponentRef<'span'>, TableDateCellProps>(({ date, ...props }, ref) => {
        return (
            <span ref={ref} {...props}>
                {getLocaleDate(date)}
            </span>
        )
    }),
)
TableDateCell.displayName = 'TableDateCell'
