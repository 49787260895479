import { type VariantProps } from 'class-variance-authority'
import { badgeVariants } from 'components/ui/badge-variants'
import { memo } from 'react'
import { cn } from 'utils/cn'

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

export const Badge: React.FC<BadgeProps> = memo(({ className, variant, ...props }) => {
    return <div className={cn(badgeVariants({ variant }), className)} {...props} />
})
Badge.displayName = 'Badge'

