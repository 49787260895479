import { LoadingIcon } from 'components/loading-icon'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { LoginSchemaType } from 'modules/user/schemas/login.schema'
import { forwardRef, memo } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { cn } from 'utils/cn'

interface LoginEmailFormProps extends Omit<React.ComponentProps<'form'>, 'onSubmit'> {
    form: UseFormReturn<LoginSchemaType>
    onSubmit: SubmitHandler<LoginSchemaType>
    isButtonDisabled?: boolean
}
export const LoginEmailForm = memo(
    forwardRef<React.ComponentRef<'form'>, LoginEmailFormProps>(
        ({ form, onSubmit, isButtonDisabled = false, className, ...props }, ref) => {
            return (
                <Form {...form}>
                    <form ref={ref} onSubmit={form.handleSubmit(onSubmit)} {...props} className={cn('space-y-4', className)}>
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="someemail@email.com" {...field} required />
                                    </FormControl>
                                    <FormDescription className="text-xs">
                                        After entering your email, you`ll have to confirm it by clicking on the link in the letter
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button type="submit" variant="default" disabled={isButtonDisabled}>
                            <LoadingIcon loading={isButtonDisabled} className="mr-2" />
                            Submit
                        </Button>
                    </form>
                </Form>
            )
        },
    ),
)
LoginEmailForm.displayName = 'LoginEmailForm'
