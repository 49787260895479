import { Button } from 'components/ui/button'
import { urlConfig } from 'config/url.config.ts'
import { memo } from 'react'
import { Link } from 'react-router-dom'

interface NotFoundPageProps {}
export const NotFoundPage: React.FC<NotFoundPageProps> = memo(() => {
    return (
        <div className="animate-in fade-in-0 flex h-full min-h-screen w-full flex-col items-center justify-center gap-8 transition-all duration-300">
            <h1 className="text-5xl">404 | Page not found</h1>
            <Button asChild>
                <Link to={urlConfig.pages.dashboard}>Go Home</Link>
            </Button>
        </div>
    )
})
NotFoundPage.displayName = 'NotFoundPage'
