import { CommandMenu } from 'components/command-menu'
import { Button } from 'components/ui/button'
import { urlConfig } from 'config/url.config.ts'
import { VenetianMask } from 'lucide-react'
import { ThemeSelector } from 'modules/theme/components/theme-selector'
import { UserAvatar } from 'modules/user/components/user-avatar'
import { UserRole } from 'modules/user/schemas/user.schema'
import { CreateProjectSheet } from 'pages/create-project-sheet'
import { forwardRef, memo } from 'react'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { Link } from 'react-router-dom'

interface NavbarProps {}
export const Navbar = memo(
    forwardRef<React.ComponentRef<'div'>, NavbarProps>(({ ...props }, ref) => {
        const isAuth = useIsAuthenticated()()
        const auth = useAuthUser()()

        return (
            <div ref={ref} {...props} className="bg-background/90 fixed z-50 w-full whitespace-nowrap border-b backdrop-blur-sm">
                <div className="container flex w-full items-center justify-between bg-none py-3">
                    <div className="flex items-center gap-2">
                        <VenetianMask />
                        <Link to={urlConfig.pages.dashboard} className="text-lg font-bold">
                            Visitor Activity
                        </Link>
                    </div>

                    <nav className="flex items-center gap-2">
                        <CommandMenu />

                        {isAuth && (
                            <>
                                {/* dashboard */}
                                <Button asChild variant="ghost" size="sm">
                                    <Link to={urlConfig.pages.dashboard}>Dashboard</Link>
                                </Button>
                                {/* logs */}
                                <Button asChild variant="ghost" size="sm">
                                    <Link to={urlConfig.pages.logs}>Logs</Link>
                                </Button>
                                {/* manage users --- SUPERADMIN only */}
                                {auth?.role === UserRole.SUPERADMIN && (
                                    <Button asChild variant="ghost" size="sm">
                                        <Link to={urlConfig.pages.manageUsers}>Manage Users</Link>
                                    </Button>
                                )}
                                {/* create project */}
                                <Button variant="ghost" size="sm" asChild>
                                    <CreateProjectSheet>Create Project</CreateProjectSheet>
                                </Button>
                            </>
                        )}

                        {/* div - fix incorrect theme selector size */}
                        <div>
                            <ThemeSelector />
                        </div>
                        <UserAvatar />
                    </nav>
                </div>
            </div>
        )
    }),
)
Navbar.displayName = 'Navbar'
