import { Navbar } from 'components/navbar'
import { TailwindIndicator } from 'components/tailwind-indicator'
import { Button } from 'components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { Toaster } from 'components/ui/toaster'
import { MessagesSquare } from 'lucide-react'
import { SnippetsModal } from 'modules/project/components/snippets-modal'
import { ThemeUpdater } from 'modules/theme/components/theme-updater'
import { ErrorPage } from 'pages/error-page'
import { memo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Routes } from 'routes'
import { NODE_ENV_DEV, NODE_ENV_PROD } from 'utils/NODE_ENV'

export const App: React.FC = memo(() => {
    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <Navbar />
            <Routes />
            {/*  */}
            <SnippetsModal />
            {/* TODO ^ refactor */}
            <Toaster />
            <ThemeUpdater />
            <TailwindIndicator show={NODE_ENV_DEV && !NODE_ENV_PROD} />
            {/* service chat test integration */}
            <div className="fixed bottom-0 right-0 p-10">
                <Popover>
                    <PopoverTrigger asChild>
                        <Button variant="outline" className="bg-foreground text-background h-16 w-16">
                            <MessagesSquare />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent align="end" className="w-[350px] rounded-lg p-0">
                        <iframe
                            src="https://service-live-chat.web.app/client"
                            className="h-[500px] w-[350px] rounded-lg border"
                        />
                    </PopoverContent>
                </Popover>
            </div>
        </ErrorBoundary>
    )
})
App.displayName = 'App'
