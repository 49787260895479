import { forwardRef, memo } from 'react'
import { cn } from 'utils/cn'

type InputProps = React.InputHTMLAttributes<HTMLInputElement>

export const Input = memo(
    forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
        return (
            <input
                type={type}
                className={cn(
                    'bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-full rounded-md border px-3 py-2 text-sm transition-all duration-300 file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
                    className,
                )}
                ref={ref}
                {...props}
            />
        )
    }),
)
Input.displayName = 'Input'
