import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { defaultIconProps } from 'config/constants.config'
import { Activity, BellOff, Database, Loader } from 'lucide-react'
import { ProjectSchemaType, ProjectStatus } from 'modules/project/schemas/project.schema'
import { forwardRef, memo, useMemo } from 'react'
import { cn } from 'utils/cn'

const iconProps = {
    ...defaultIconProps,
    className: 'text-muted-foreground',
}

interface DashboardCardsProps extends React.ComponentProps<'div'> {
    data: ProjectSchemaType[]
}
export const DashboardCards = memo(
    forwardRef<React.ComponentRef<'div'>, DashboardCardsProps>(({ data, className, ...props }, ref) => {
        const total = useMemo(() => data.length, [data.length])
        const active = useMemo(() => data.filter(item => item.status === ProjectStatus.ACTIVE).length, [data])
        const disabled = useMemo(() => data.filter(item => item.status === ProjectStatus.DISABLED).length, [data])
        const pending = useMemo(() => data.filter(item => item.status === ProjectStatus.PENDING).length, [data])

        const projectCards = useMemo<
            Array<{
                title: string
                value: number
                icon: React.ReactNode
            }>
        >(
            () => [
                {
                    title: 'Total Projects',
                    value: total,
                    icon: <Database {...iconProps} />,
                },
                {
                    title: 'Active Projects',
                    value: active,
                    icon: <Activity {...iconProps} />,
                },
                {
                    title: 'Disabled Projects',
                    value: disabled,
                    icon: <BellOff {...iconProps} />,
                },
                {
                    title: 'Pending Projects',
                    value: pending,
                    icon: <Loader {...iconProps} />,
                },
            ],
            [active, disabled, pending, total],
        )

        return (
            <div ref={ref} {...props} className={cn('flex gap-5', className)}>
                {projectCards.map(card => (
                    <Card key={card.title} className="flex-1">
                        <CardHeader>
                            <CardTitle className="text-sm">{card.title}</CardTitle>
                        </CardHeader>
                        <CardContent className="flex items-center justify-between">
                            <h1 className="text-3xl">{card.value}</h1>
                            {card.icon}
                        </CardContent>
                    </Card>
                ))}
            </div>
        )
    }),
)
DashboardCards.displayName = 'DashboardCards'
