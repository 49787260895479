type GetOsIconName = (os?: string) => string | undefined
export const getOsIconName: GetOsIconName = os => {
    const osName = os?.toLowerCase()
    if (osName?.includes('windows')) {
        return 'windows'
    }
    if (osName?.includes('mac')) {
        return 'macos'
    }
    if (osName?.includes('linux') || osName?.includes('ubuntu')) {
        return 'linux'
    }
    if (osName?.includes('android')) {
        return 'android'
    }
    if (osName?.includes('ios')) {
        return 'ios'
    }
}
