import { defaultIconProps } from 'config/constants.config'
import { X } from 'lucide-react'
import { forwardRef, memo } from 'react'
import { cn } from 'utils/cn'

const iconSizeClassName = 'h-[18px] min-h-[18px] w-[18px] min-w-[18px]'

interface SlotItemWithIconProps {
    Icon: React.ComponentType<React.ComponentProps<typeof X>>
    className?: string
}
export const SlotItemWithIcon = memo(
    forwardRef<React.ComponentRef<typeof X>, React.PropsWithChildren<SlotItemWithIconProps>>(
        ({ Icon, className, children, ...props }, ref) => {
            return (
                <>
                    <Icon ref={ref} {...defaultIconProps} {...props} className={cn('mr-3', iconSizeClassName, className)} />
                    {children}
                </>
            )
        },
    ),
)
SlotItemWithIcon.displayName = 'SlotItemWithIcon'

interface SlotItemWithIconReversedProps {
    Icon: React.ComponentType<React.ComponentProps<typeof X>>
    wrapperClassName?: string
    className?: string
}
export const SlotItemWithIconReversed = memo(
    forwardRef<React.ComponentRef<'div'>, React.PropsWithChildren<SlotItemWithIconReversedProps>>(
        ({ Icon, wrapperClassName, className, children, ...props }, ref) => {
            return (
                <div ref={ref} className={cn('flex w-full', wrapperClassName)}>
                    {children}
                    <Icon {...defaultIconProps} {...props} className={cn('ml-auto', iconSizeClassName, className)} />
                </div>
            )
        },
    ),
)
SlotItemWithIconReversed.displayName = 'SlotItemWithIconReversed'
