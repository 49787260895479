import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef, memo } from 'react'
import { cn } from 'utils/cn'

const labelVariants = cva('text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70')

const Label = memo(
    forwardRef<
        React.ElementRef<typeof LabelPrimitive.Root>,
        React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
    >(({ className, ...props }, ref) => <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />),
)
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
