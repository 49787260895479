import { FullPageLoader } from 'components/full-page-loader'
import { Heading } from 'components/heading'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { DatePickerWithRange } from 'components/ui/date-picker-with-range'
import { DetailsBrowsersChart } from 'modules/details/components/details-browsers-chart'
import { detailsColumns } from 'modules/details/components/details-columns'
import { DetailsCountriesChart } from 'modules/details/components/details-countries-chart'
import { DetailsMobilePcChart } from 'modules/details/components/details-mobile-pc-chart'
import { DetailsOsChart } from 'modules/details/components/details-os-chart'
import { DetailsRecentUsers } from 'modules/details/components/details-recent-users'
import { DetailsUsersChart } from 'modules/details/components/details-users-chart'
import { NotFoundPage } from 'pages/not-found-page'
import { memo, useCallback, useMemo, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { Link, useParams } from 'react-router-dom'
import { useGetProjectQuery } from 'redux-store/api/project-api'
import { useGetAllReportsQuery } from 'redux-store/api/report-api'

interface DetailsPageProps {}
export const DetailsPage: React.FC<DetailsPageProps> = memo(() => {
    const { id } = useParams()
    if (!id) throw new Error('No id provided')
    const [date, setDate] = useState<DateRange | undefined>()
    const { data: project, isLoading: projectLoading, isError: projectError } = useGetProjectQuery(id)
    const { data: reports, isLoading: reportsLoading, isError: reportsError } = useGetAllReportsQuery(id)

    const filteredData = useMemo(() => {
        if (!reports) {
            return []
        }
        if (!date) {
            return reports
        }
        return reports.filter(report => {
            if (!date.from || !date.to) return
            const reportDate = new Date(report.date)
            return reportDate >= date.from && reportDate <= date.to
        })
    }, [date, reports])

    const clearVisible = useMemo(() => {
        if (date) {
            return true
        }
        return false
    }, [date])

    const onClear = useCallback<() => void>(() => {
        setDate(undefined)
    }, [])

    if (reportsLoading || projectLoading) {
        return <FullPageLoader />
    }
    if (reportsError || projectError) {
        return <NotFoundPage />
    }

    return (
        <div className="animate-in fade-in-0 bg-background container min-h-screen space-y-5 pt-20 transition-all duration-300">
            <div className="flex items-center justify-between">
                <Heading title={project?.name ?? 'Unknown Project'} description={`Project ${id}`} />
                <div className="flex flex-col items-end">
                    <Badge className="w-fit capitalize">{project?.status ?? 'Unknown Status'}</Badge>
                    <Button variant="link" asChild className="p-0 text-sm font-normal">
                        <Link to={project?.url ?? ''}>{project?.url ?? ''}</Link>
                    </Button>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
                <DetailsUsersChart data={reports ?? []} />
                <DetailsRecentUsers data={reports ?? []} />
            </div>
            <DataTable
                columns={detailsColumns}
                data={filteredData ?? []}
                pagination
                initialSorting={{
                    column: 'date',
                    direction: 'desc',
                }}
                initialHidden={[
                    'continent',
                    'continentCode',
                    'country',
                    'countryCode',
                    'city',
                    'region',
                    'regionName',
                    'lat',
                    'lng',
                    'internetProvider',
                    'organization',
                    'as',
                    'asName',
                    'postalCode',
                    'timeZone',
                    'mobile',
                    'proxy',
                    'hosting',
                    'map',
                ]}
                className="whitespace-break-spaces md:whitespace-nowrap"
                headClassName="px-2"
                cellClassName="p-2"
            >
                <DataTableToolbar isClearVisible={clearVisible} onClear={onClear}>
                    <DatePickerWithRange date={date} onDateChange={setDate} />
                    <DataTableInputFilter column="locationHref" label="URL" />
                    <DataTableInputFilter column="ip" label="IP" />
                    <DataTableInputFilter column="country" label="Country" />
                </DataTableToolbar>
            </DataTable>
            <div className="grid grid-cols-2 gap-5">
                <DetailsCountriesChart data={reports ?? []} />
                <DetailsMobilePcChart data={reports ?? []} />
                <DetailsBrowsersChart data={reports ?? []} />
                <DetailsOsChart data={reports ?? []} />
            </div>
            <div className="h-5" />
        </div>
    )
})
DetailsPage.displayName = 'DetailsPage'
