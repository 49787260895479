import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { forwardRef, memo } from 'react'
import { cn } from 'utils/cn'

interface LogsCardProps extends React.ComponentProps<typeof Card> {
    title?: string
    description?: string
    children?: React.ReactNode
}
export const LogsCard = memo(
    forwardRef<React.ComponentRef<typeof Card>, LogsCardProps>(({ title, description, className, children, ...props }, ref) => {
        return (
            <Card ref={ref} {...props} className={cn('whitespace-pre-wrap', className)}>
                {(title || description) && (
                    <CardHeader>
                        {title && <CardTitle>{title}</CardTitle>}
                        {description && <CardDescription>{description}</CardDescription>}
                    </CardHeader>
                )}
                <CardContent className="leading-relaxed">{children}</CardContent>
            </Card>
        )
    }),
)
