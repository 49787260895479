import { PieChart } from 'components/pie-chart'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { forwardRef, memo, useMemo } from 'react'

interface DetailsMobilePcChartProps extends React.ComponentProps<typeof Card> {
    data: ReportSchemaType[]
}
export const DetailsMobilePcChart = memo(
    forwardRef<React.ComponentRef<typeof Card>, DetailsMobilePcChartProps>(({ data, ...props }, ref) => {
        const chartData = useMemo(() => {
            return [
                {
                    name: 'Mobile',
                    value: data.map(item => item.mobile).filter(item => item).length,
                },
                {
                    name: 'PC',
                    value: data.map(item => item.mobile).filter(item => !item).length,
                },
            ]
        }, [data])

        return (
            <Card ref={ref} {...props}>
                <CardHeader>
                    <CardTitle>Mobile vs PC</CardTitle>
                    <CardDescription>Which devices are used by your users</CardDescription>
                </CardHeader>
                <CardContent>
                    <PieChart data={chartData} height={350} />
                </CardContent>
            </Card>
        )
    }),
)
DetailsMobilePcChart.displayName = 'DetailsMobilePcChart'
