import { LoginSchema } from 'modules/user/schemas/login.schema'
import { z } from 'zod'

export enum UserRole {
    USER = 'user',
    ADMIN = 'admin',
    SUPERADMIN = 'superadmin',
}

export const UserSchema = z.object({
    id: z.string().nonempty(),
    role: z.nativeEnum(UserRole),
    ...LoginSchema.shape,
})

export type UserSchemaType = z.infer<typeof UserSchema>

export const NewUserSchema = UserSchema.omit({ id: true })
export type NewUserSchemaType = z.infer<typeof NewUserSchema>
