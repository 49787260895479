import { BarChart } from 'components/bar-chart'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { forwardRef, memo, useMemo } from 'react'
import { UAParser } from 'ua-parser-js'

interface DetailsBrowsersChartProps extends React.ComponentProps<typeof Card> {
    data: ReportSchemaType[]
}
export const DetailsBrowsersChart = memo(
    forwardRef<React.ComponentRef<typeof Card>, DetailsBrowsersChartProps>(({ data, ...props }, ref) => {
        const chartData = useMemo(() => {
            const modifiedData = [
                ...new Set(
                    data
                        .map(item => {
                            const ua = new UAParser(item.userAgent)
                            return ua.getBrowser().name
                        })
                        .filter(Boolean),
                ),
            ]
            return modifiedData
                .map(item => ({
                    name: item || 'Other',
                    value: data.filter(i => {
                        const ua = new UAParser(i.userAgent)
                        return ua.getBrowser().name === item
                    }).length,
                }))
                .sort((a, b) => {
                    if (a.name === 'Other') return 1
                    if (b.name === 'Other') return -1
                    return b.value - a.value
                })
        }, [data])

        return (
            <Card ref={ref} {...props}>
                <CardHeader>
                    <CardTitle>Browsers</CardTitle>
                    <CardDescription>Which are used by your users</CardDescription>
                </CardHeader>
                <CardContent>
                    <BarChart data={chartData} height={350} />
                </CardContent>
            </Card>
        )
    }),
)
DetailsBrowsersChart.displayName = 'DetailsBrowsersChart'
