import { BarChart } from 'components/bar-chart'
import { Card, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import dayjs from 'dayjs'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { forwardRef, memo, useMemo } from 'react'

interface DetailsUsersChartProps extends React.ComponentProps<typeof Card> {
    data: ReportSchemaType[]
}
export const DetailsUsersChart = memo(
    forwardRef<React.ComponentRef<typeof Card>, DetailsUsersChartProps>(({ data, ...props }, ref) => {
        const chartData = useMemo(() => {
            const modifiedData = data.filter(item => dayjs(item.date).isSame(dayjs(), 'week'))
            return Array.from({ length: 7 }).map((_, index) => ({
                name: dayjs()
                    .day(index + 1)
                    .format('ddd'),
                value: modifiedData.filter(item => dayjs(item.date).get('day') === index + 1).length,
            }))
        }, [data])

        return (
            <Card ref={ref} {...props}>
                <CardHeader>
                    <CardTitle>Overview</CardTitle>
                    <CardDescription>Active users this week</CardDescription>
                </CardHeader>
                <BarChart data={chartData} height={350} />
            </Card>
        )
    }),
)
DetailsUsersChart.displayName = 'DeatilsUsersChart'
