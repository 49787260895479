import { Button } from 'components/ui/button'
import { forwardRef, memo } from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'utils/cn'

interface TableUrlCellProps extends React.ComponentProps<typeof Button> {
    url: string
    linkProps?: React.ComponentProps<typeof Link>
}
export const TableUrlCell = memo(
    forwardRef<React.ComponentRef<typeof Button>, TableUrlCellProps>(({ url, className, linkProps, ...props }, ref) => {
        return (
            <Button ref={ref} variant="link" asChild {...props} className={cn('p-0 font-normal', className)}>
                <Link to={url} {...linkProps}>
                    {url}
                </Link>
            </Button>
        )
    }),
)
TableUrlCell.displayName = 'TableUrlCell'
