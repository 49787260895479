import { ColumnDef } from '@tanstack/react-table'
import { Button } from 'components/ui/button'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { DashboardRowActionsCell } from 'modules/dashboard/cells/dashboard-data-table-row-actions-cell'
import { DashboardDetailsCell } from 'modules/dashboard/cells/dashboard-details-cell'
import { DashboardSnippetsCell } from 'modules/dashboard/cells/dashboard-snippets-cell'
import { DashboardStatusCell } from 'modules/dashboard/cells/dashboard-status-cell'
import { ProjectSchemaType } from 'modules/project/schemas/project.schema'
import { Link } from 'react-router-dom'

export const dashboardColumns: ColumnDef<ProjectSchemaType>[] = [
    {
        accessorKey: 'id',
        header: 'ID',
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        cell: ({ row }) => <span>{row.getValue('name')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'url',
        header: ({ column }) => <DataTableColumnHeader column={column} title="URL" />,
        cell: ({ row }) => {
            const url = row.getValue('url') as ProjectSchemaType['url']
            return (
                <Button variant="link" asChild className="font-normal">
                    <Link to={url}>{url}</Link>
                </Button>
            )
        },
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'status',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
        cell: ({ row }) => (
            <DashboardStatusCell
                id={row.getValue('id')}
                name={row.getValue('name')}
                status={row.getValue('status')}
                className="w-full max-w-[150px]"
            />
        ),
        sortingFn: 'text',
        filterFn: 'weakEquals',
    },
    {
        header: 'Details',
        cell: ({ row }) => <DashboardDetailsCell id={row.getValue('id')} status={row.getValue('status')} />,
    },
    {
        header: 'Snippets',
        cell: ({ row }) => (
            <DashboardSnippetsCell id={row.getValue('id')} name={row.getValue('name')} className="w-full max-w-[150px]" />
        ),
    },
    {
        header: 'More',
        cell: ({ row }) => <DashboardRowActionsCell id={row.getValue('id')} name={row.getValue('name')} />,
    },
]
