import { defaultIconProps } from 'config/constants.config'
import { Check, Copy } from 'lucide-react'
import { forwardRef, memo, useCallback, useState } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'

interface CodeSnippetProps extends React.ComponentProps<typeof SyntaxHighlighter> {
    children: string
    label?: string
}
export const CodeSnippet = memo(
    forwardRef<React.ComponentRef<typeof SyntaxHighlighter>, CodeSnippetProps>(({ label = 'Code', children, ...props }, ref) => {
        const [copy, setCopy] = useState<boolean>(false)

        const copyToClipboard = useCallback<() => void>(() => {
            navigator.clipboard.writeText(children)
            setCopy(true)
            setTimeout(() => setCopy(false), 2000)
        }, [children])

        return (
            <div className="rounded-lg bg-[#3a404d] shadow">
                <div className="flex items-center justify-between px-4 py-2 text-xs text-white">
                    <p>{label}</p>
                    <button onClick={copyToClipboard} className="inline-flex items-center gap-1 px-1">
                        {copy ? (
                            <Check {...defaultIconProps} className="animate-in fade-in-0 transition-all duration-300" />
                        ) : (
                            <Copy {...defaultIconProps} className="animate-in fade-in-0 transition-all duration-300" />
                        )}
                    </button>
                </div>
                <SyntaxHighlighter
                    ref={ref}
                    style={atomOneDark}
                    customStyle={{
                        borderRadius: '0 0 0.5rem 0.5rem',
                        padding: '2rem',
                    }}
                    {...props}
                >
                    {children}
                </SyntaxHighlighter>
            </div>
        )
    }),
)
CodeSnippet.displayName = 'CodeSnippet'
