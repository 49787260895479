type GetBrowserIconName = (browser?: string) => string | undefined
export const getBrowserIconName: GetBrowserIconName = browser => {
    const browserName = browser?.toLowerCase()
    if (browserName?.includes('chrome') || browserName?.includes('chromium')) {
        return 'chrome'
    }
    if (browserName?.includes('firefox')) {
        return 'firefox'
    }
    if (browserName?.includes('safari')) {
        return 'safari'
    }
    if (browserName?.includes('opera')) {
        return 'opera'
    }
    if (browserName?.includes('edge')) {
        return 'edge'
    }
}
