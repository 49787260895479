import { Button } from 'components/ui/button'
import { toastConfig } from 'config/toast.config'
import { ProjectSchemaType, ProjectStatus } from 'modules/project/schemas/project.schema'
import React, { forwardRef, memo, useCallback, useMemo } from 'react'
import { useUpdateProjectMutation } from 'redux-store/api/project-api'
import { cn } from 'utils/cn'

interface DashboardStatusCellProps extends React.ComponentProps<typeof Button> {
    id: ProjectSchemaType['id']
    name: ProjectSchemaType['name']
    status: ProjectStatus
}
export const DashboardStatusCell = memo(
    forwardRef<React.ComponentRef<typeof Button>, DashboardStatusCellProps>(({ id, name, status, className, ...props }, ref) => {
        const [updateProject, updateProjectRes] = useUpdateProjectMutation()

        const buttonVariant = useMemo(() => {
            if (status === ProjectStatus.ACTIVE) return 'success'
            if (status === ProjectStatus.DISABLED) return 'destructive'
            return 'ghost'
        }, [status])

        const onClick = useCallback<() => void>(() => {
            try {
                if (status === ProjectStatus.PENDING) {
                    toastConfig.project.update.pending(name)
                    return
                }

                updateProject({
                    id,
                    status: status === ProjectStatus.ACTIVE ? ProjectStatus.DISABLED : ProjectStatus.ACTIVE,
                })
                if (updateProjectRes.isError) {
                    console.error(updateProjectRes.error)
                    throw new Error()
                }
                toastConfig.project.update.success(name, status)
            } catch (erorr) {
                toastConfig.project.update.error(name)
            }
        }, [id, name, status, updateProject, updateProjectRes.error, updateProjectRes.isError])

        return (
            <Button
                ref={ref}
                variant={buttonVariant}
                size="sm"
                onClick={onClick}
                {...props}
                className={cn('capitalize', className)}
            >
                {status}
            </Button>
        )
    }),
)
DashboardStatusCell.displayName = 'StatusCell'
