import { FullPageLoader } from 'components/full-page-loader'
import { Heading } from 'components/heading'
import { DataTable } from 'components/ui/data-table'
import { DataTableFacetedFilter } from 'components/ui/data-table-faceted-filter'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { DashboardCards } from 'modules/dashboard/components/dashboard-cards'
import { dashboardColumns } from 'modules/dashboard/components/dashboard-columns'
import { ProjectStatus } from 'modules/project/schemas/project.schema'
import { memo } from 'react'
import { useGetAllProjectsQuery } from 'redux-store/api/project-api'

interface DashboardPageProps {}
export const DashboardPage: React.FC<DashboardPageProps> = memo(() => {
    const { data, isLoading } = useGetAllProjectsQuery()

    if (isLoading) {
        return <FullPageLoader />
    }

    return (
        <div className="animate-in fade-in-0 container min-h-screen space-y-5 pt-20 transition-all duration-300">
            <Heading title="Dashboard" description="Manage your projects" />
            <DashboardCards data={data ?? []} />
            <DataTable
                columns={dashboardColumns}
                data={data ?? []}
                pagination
                initialSorting={{
                    column: 'name',
                    direction: 'asc',
                }}
                initialHidden={['id']}
            >
                <DataTableToolbar>
                    <DataTableInputFilter column="name" label="Name" />
                    <DataTableInputFilter column="url" label="URL" />
                    <DataTableFacetedFilter
                        column="status"
                        options={Object.values(ProjectStatus).map(status => ({ data: status }))}
                    />
                </DataTableToolbar>
            </DataTable>
            <div className="h-5" />
        </div>
    )
})
DashboardPage.displayName = 'DashboardPage'
